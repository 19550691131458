import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Foto / Video" />
      <Layout>
        <SimpleBanner title="Foto / Video">
          <StaticImage
            className="banner__image"
            src="../../images/organisation/fotovideo.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Każde wielkie wydarzenie powinno zostać odpowiednio uwiecznione.
            Wychodząc na przeciw naszym klientom oferujemy usługi foto oraz
            wideo w atrakcyjnej cenowo ofercie wraz z zespołem muzycznym.
            Współpracujemy tylko z najlepszymi, gdzie wieloletnie doświadczenie
            oraz setki wykonanych zdjeć i ujęć grają pierwszorzędna role.
            Dlatego też materiały pochodzące od naszych usługodawców cechuje
            profesjonalizm oraz fotografie/ filmy na najwyższym poziomie.
            Zaangażowanie oraz entuzjazm i pomysłowość sprawiają, że praca z
            nimi przynosi niesamowite efekty, z których nawet najbardziej
            wymagający klienci bedą zadowoleni. Zdjęcia i filmy wykonywane są na
            wysokiej klasy sprzęcie, dzięki czemu otrzymujecie kompletny i
            rzetelny materiał. Zachęcamy do zapoznania się z ofertą w pakiecie z
            zespołem gdzie możecie skorzystać z atrakcyjnych cenowo pakietów lub
            dodatkowych gratisów.
          </p>
          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
